import React, { useState, useEffect, useRef, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import CommonButton from '../common/CommonButton.tsx';
import { useParams, useNavigate } from 'react-router-dom';
import { FaChevronCircleDown, FaSpinner } from 'react-icons/fa';
import { useUser } from '../../contexts/UserContext.js';
import { useColorMode } from '../../contexts/ColorMode.js';
import SingleSelect from '../common/SingleSelect.js';
import { useAlertDialog } from '../../contexts/AlertDialogContext.js';
import axios from 'axios';
import { getHeaders } from '../../utils/web.js';

import ProgressIndicator from '../quick_editor/ProgressIndicator.js';

import {
  IMAGE_GENERAITON_MODEL_TYPES,
  VIDEO_GENERATION_MODEL_TYPES,
} from '../../constants/Types.ts';

const API_SERVER = process.env.REACT_APP_PROCESSOR_API;

export default function OneshotEditor() {
  const { user } = useUser();
  const { colorMode } = useColorMode();
  const { id } = useParams();
  const navigate = useNavigate();

  // [1] Basic session & form state
  const [sessionDetails, setSessionDetails] = useState(null);
  const [promptText, setPromptText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerationPending, setIsGenerationPending] = useState(false);
  const [expressGenerationStatus, setExpressGenerationStatus] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Show/hide the inline progress indicator
  const [showResultDisplay, setShowResultDisplay] = useState(false);

  const pollIntervalRef = useRef(null);

  // Filter out “Express” models
  const expressImageModels = IMAGE_GENERAITON_MODEL_TYPES
    .filter((m) => m.isExpressModel)
    .map((m) => ({ label: m.name, value: m.key }));

  const expressVideoModels = VIDEO_GENERATION_MODEL_TYPES
    .filter((m) => m.isExpressModel)
    .map((m) => ({ label: m.name, value: m.key }));

  // Aspect Ratio
  const aspectRatioOptions = [
    { label: '16:9 (Landscape)', value: '16:9' },
    { label: '9:16 (Portrait)', value: '9:16' },
  ];

  // Load model preferences from localStorage
  const [selectedImageModel, setSelectedImageModel] = useState(() => {
    const saved = localStorage.getItem('defaultVidGPTImageGenerationModel');
    const found = expressImageModels.find((m) => m.value === saved);
    return found || expressImageModels[0];
  });

  const [selectedVideoModel, setSelectedVideoModel] = useState(() => {
    const saved = localStorage.getItem('defaultVIdGPTVideoGenerationModel');
    const found = expressVideoModels.find((m) => m.value === saved);
    return found || expressVideoModels[0];
  });

  const [selectedAspectRatioOption, setSelectedAspectRatioOption] = useState(() => {
    const storedAspectRatioValue = localStorage.getItem('defaultVidGPTAspectRatio');
    const foundAspectRatio = aspectRatioOptions.find(
      (option) => option.value === storedAspectRatioValue
    );
    return foundAspectRatio || aspectRatioOptions[0];
  });

  // Save model selections to localStorage
  useEffect(() => {
    if (selectedImageModel?.value) {
      localStorage.setItem(
        'defaultVidGPTImageGenerationModel',
        selectedImageModel.value
      );
    }
  }, [selectedImageModel]);

  useEffect(() => {
    if (selectedVideoModel?.value) {
      localStorage.setItem(
        'defaultVIdGPTVideoGenerationModel',
        selectedVideoModel.value
      );
    }
  }, [selectedVideoModel]);

  useEffect(() => {
    if (selectedAspectRatioOption?.value) {
      localStorage.setItem(
        'defaultVidGPTAspectRatio',
        selectedAspectRatioOption.value
      );
    }
  }, [selectedAspectRatioOption]);

  // Disable form if not premium or credits too low
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (!user || user.generationCredits < 300) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user]);

  // Helper to reset everything
  const resetForm = () => {
    setPromptText('');
    setShowResultDisplay(false);
    setErrorMessage(null);
    setVideoLink(null);
    setExpressGenerationStatus(null);
    setIsGenerationPending(false);
    setIsSubmitting(false);
  };

  // Cleanup poll on unmount
  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, []);

  // Load session details if there's an ID
  useEffect(() => {
    if (id) {
      resetForm();
      getSessionDetails();
    }
  }, [id]);

  const getSessionDetails = async () => {
    try {
      const headers = getHeaders();
      const resData = await axios.get(
        `${API_SERVER}/quick_session/details?sessionId=${id}`,
        headers
      );
      const response = resData.data;
      setSessionDetails(response);

      // If the server says it's pending, show "pending" UI & poll
      if (response.videoGenerationPending) {
        setIsGenerationPending(true);
        setShowResultDisplay(true);
        pollGenerationStatus();
      }
      // If completed, set up the final video link
      if (response.status === 'COMPLETED' && response.videoLink) {
        setVideoLink(response.videoLink);
        setShowResultDisplay(true);
      }
    } catch (err) {
      console.error('Error fetching session details:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!promptText.trim()) {
      alert('Please enter some text.');
      return;
    }
    if (!id) return;

    setIsSubmitting(true);
    setIsGenerationPending(true);
    setShowResultDisplay(true);
    setErrorMessage(null);
    setVideoLink(null);
    setExpressGenerationStatus(null);

    const payload = {
      prompt: promptText,
      sessionID: id,
      aspectRatio: selectedAspectRatioOption?.value,
      imageModel: selectedImageModel?.value,
      videoGenerationModel: selectedVideoModel?.value,
    };

    try {
      const headers = getHeaders();
      await axios.post(`${API_SERVER}/vidgpt/create`, payload, headers);
      pollGenerationStatus();
    } catch (error) {
      console.error('Error submitting theme text:', error);
      setErrorMessage({ error: 'An unexpected error occurred.' });
      setIsGenerationPending(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const pollGenerationStatus = () => {
    if (pollIntervalRef.current) clearInterval(pollIntervalRef.current);
    pollIntervalRef.current = setInterval(async () => {
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${API_SERVER}/quick_session/status?sessionId=${id}`,
          headers
        );
        const resData = response.data;

        setExpressGenerationStatus(resData.expressGenerationStatus);

        if (resData.status === 'COMPLETED') {
          clearInterval(pollIntervalRef.current);
          setIsGenerationPending(false);
          setVideoLink(resData.videoLink);
        } else if (resData.status === 'FAILED') {
          clearInterval(pollIntervalRef.current);
          setIsGenerationPending(false);
          setErrorMessage({ error: 'Video generation failed.' });
        }
      } catch (error) {
        console.error('Error fetching generation status:', error);
        clearInterval(pollIntervalRef.current);
        setIsGenerationPending(false);
        setErrorMessage({
          error: 'An unexpected error occurred while checking status.',
        });
      }
    }, 5000);
  };

  // Buttons & actions
  const viewInStudio = () => {
    navigate(`/video/${id}`);
  };

  const purchaseCreditsForUser = () => {
    // ...
  };

  // Show/hide pricing details
  const [pricingDetailsDisplay, setPricingDetailsDisplay] = useState(false);
  const togglePricingDetailsDisplay = () => {
    setPricingDetailsDisplay(!pricingDetailsDisplay);
  };

  // Determine overall "render state": 'idle', 'pending', 'complete'
  const renderState = useMemo(() => {
    if (isGenerationPending) return 'pending';
    if (videoLink) return 'complete';
    return 'idle';
  }, [isGenerationPending, videoLink]);

  // "Render Again" -> reset everything
  const handleRenderAgain = () => {
    resetForm();
  };

  // For downloading final video
  const downloadVideoHref = videoLink ? `${API_SERVER}/${videoLink}` : '#';

  // "Disable" the text area and the submit if not idle or user lacks credits
  const isFormDisabled = renderState !== 'idle' || isDisabled;

  return (
    <div className="mt-[20px] relative">
      {/* Top Bar (header) */}
      <div
        className="
          flex justify-between items-center
          p-2 bg-neutral-950 text-white
          pt-8 mt-8
          rounded-md shadow-md
          relative
        "
      >
        <h2 className="text-lg font-bold text-white pl-2">
          VidGPT by SamsarOne
        </h2>

        {/* Right-Side Toolbar */}
        <div className="flex items-center space-x-4 mr-2">
          {/* Always display SingleSelects */}
          <SingleSelect
            value={selectedAspectRatioOption}
            onChange={setSelectedAspectRatioOption}
            options={aspectRatioOptions}
            className="w-40"
          />
          <SingleSelect
            value={selectedImageModel}
            onChange={setSelectedImageModel}
            options={expressImageModels}
            className="w-40"
          />
          <SingleSelect
            value={selectedVideoModel}
            onChange={setSelectedVideoModel}
            options={expressVideoModels}
            className="w-40"
          />

          {/* Show "Render pending" if pending */}
          {renderState === 'pending' && (
            <div className="flex items-center space-x-2">
              <span>Render pending</span>
              <FaSpinner className="animate-spin" />
            </div>
          )}

          {/* Show "Download/Studio/RenderAgain" if completed */}
          {renderState === 'complete' && (
            <div className="flex items-center space-x-2">
              <a
                href={downloadVideoHref}
                download="generated_video.mp4"
                className="bg-blue-600 px-3 py-1 rounded text-white"
              >
                Download Video
              </a>
              <button
                className="bg-blue-600 px-3 py-1 rounded text-white"
                onClick={viewInStudio}
              >
                View in Studio
              </button>
              <button
                className="bg-blue-600 px-3 py-1 rounded text-white"
                onClick={handleRenderAgain}
              >
                Render Again
              </button>
            </div>
          )}
        </div>
      </div>

      {/* ProgressIndicator: if generating or done, we show the progress area */}
      {showResultDisplay && (
        <div className="mt-4 transition-all duration-500 ease-in-out">
          <ProgressIndicator
            isGenerationPending={isGenerationPending}
            expressGenerationStatus={expressGenerationStatus}
            videoLink={videoLink}
            errorMessage={errorMessage}
            purchaseCreditsForUser={purchaseCreditsForUser}
          />
        </div>
      )}

      {/*
        Textarea & Submit Form
        Always visible, but disabled if pending/complete
      */}
      <form onSubmit={handleSubmit}>
        <TextareaAutosize
          minRows={8}
          maxRows={20}
          disabled={isFormDisabled}
          className={`
            w-full bg-gray-950 text-white pl-4 pt-4 p-2 rounded mt-4
            ${isFormDisabled ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          placeholder={`Enter a topic for your story-video, e.g.:\n"A 1-minute journey through the cosmos"`}
          name="promptText"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
        />
        <div className="mt-4 relative">
          <div className="flex justify-center">
            <CommonButton
              type="submit"
              isDisabled={isFormDisabled || isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </CommonButton>
          </div>

          {/* Pricing Info (optional) */}
          <div className="md:absolute md:right-0 top-0 text-white p-2 bg-gray-900 rounded text-center mt-4 md:mt-0 w-full md:w-auto">
            <div className="relative">
              <div
                className="flex justify-end font-bold text-sm text-neutral-100 cursor-pointer"
                onClick={togglePricingDetailsDisplay}
              >
                Pricing will be shown at completion
                <FaChevronCircleDown className="inline-flex ml-2 mt-1" />
              </div>
              {pricingDetailsDisplay && (
                <div className="mt-1 text-sm w-full text-right">
                  <div>
                    The price is calculated as 100 credits per 10 seconds of video.
                  </div>
                  <div>
                    For example, a 1-minute video will consume 600 credits.
                  </div>
                  {!user?.isPremiumUser && (
                    <div className="text-xs mt-1 text-red-500">
                      This feature is only available to premium users.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>

      {/* Example usage area (always visible) */}
      <div className="mt-4 text-neutral-100">
        <div className="flex items-center mb-2">
          <span className="font-bold text-lg">Examples</span>
          <FaChevronCircleDown className="ml-2" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="border border-gray-700 p-2 rounded">
            <p>“A short video about a robot’s day.”</p>
          </div>
          <div className="border border-gray-700 p-2 rounded">
            <p>“A whimsical 30-second tale of sailing ships.”</p>
          </div>
        </div>
      </div>
    </div>
  );
}
