import React, { useState } from 'react';
import SecondaryButton from '../../../common/SecondaryButton.tsx';

export default function VideoAiVideoOptionsViewer(props) {
  const {
    currentLayer,
    onDeleteLayer,
    removeVideoLayer,
    currentLayerHasSpeechLayer,
    requestLipSyncToSpeech,
    requestAddSyncedSoundEffect 
  } = props;

  const [showSoundEffectPrompt, setShowSoundEffectPrompt] = useState(false);
  const [soundEffectPrompt, setSoundEffectPrompt] = useState('');

  const handleDeleteLayer = () => {
    if (removeVideoLayer) {
      removeVideoLayer(currentLayer);
    }
  };

  const handleRequestLipSync = () => {
    if (requestLipSyncToSpeech) {
      requestLipSyncToSpeech(currentLayer);
    }
  };

  const handleRequestSoundEffect = () => {
    setShowSoundEffectPrompt((prev) => !prev);
  };

  const handleSoundEffectSubmit = () => {
    if (requestAddSyncedSoundEffect) {
      // You can pass just the prompt, or add the `currentLayer` as needed
      // Example: requestAddSoundEffect({ layer: currentLayer, prompt: soundEffectPrompt });
      const payload = {
        prompt: soundEffectPrompt,
      }
      requestAddSyncedSoundEffect(payload);
    }
    // Optionally hide the prompt after submission and clear it
    setShowSoundEffectPrompt(false);
    setSoundEffectPrompt('');
  };

  return (
    <div className="flex flex-col items-center justify-center mt-2 mx-auto">
      {/* Lip Sync Section */}
      {currentLayerHasSpeechLayer ? (
        <div className="mb-2">
          <SecondaryButton onClick={handleRequestLipSync}>
            Request Lip Sync
          </SecondaryButton>
        </div>
      ) : (
        <div className="mb-2 text-sm">
          <p>Create a speech layer</p>
          <p>to generate lipsync</p>
        </div>
      )}

      {/* Sound Effect Section */}
      <div className="mt-2 mb-2">
        <SecondaryButton onClick={handleRequestSoundEffect}>
          Request Sound Effect
        </SecondaryButton>
        {showSoundEffectPrompt && (
          <div className="flex flex-col items-center mt-2">
            <textarea
              className="text-sm p-1
              bg-gray-800
              border border-gray-300 rounded"
              placeholder="Enter prompt for effect"
              value={soundEffectPrompt}
              onChange={(e) => setSoundEffectPrompt(e.target.value)}
            />
            <SecondaryButton
              onClick={handleSoundEffectSubmit}
              extraClasses="mt-2"
            >
              Submit
            </SecondaryButton>
          </div>
        )}
      </div>

      {/* Delete Button */}
      <div className="mt-4 mb-2">
        <SecondaryButton
          onClick={handleDeleteLayer}
          extraClasses="bg-red-500 hover:bg-red-600"
        >
          Delete Video Layer
        </SecondaryButton>
      </div>
    </div>
  );
}
