export const ELEVENLABS_TTS = 
[
    {
      voiceId: "9BWtsMINqrJLrRacOk9x",
      gender: "female",
      age: "middle-aged",
      style: "expressive",
      accent: "American",
      description: "social media",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/9BWtsMINqrJLrRacOk9x/405766b8-1f4e-4d3c-aba1-6f25333823ec.mp3",
      value: "9BWtsMINqrJLrRacOk9x",
      name: "Aria",
      label: "Aria",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "CwhRBWXzGAHq8TQ4Fs17",
      gender: "male",
      age: "middle-aged",
      style: "confident",
      accent: "American",
      description: "social media",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/CwhRBWXzGAHq8TQ4Fs17/58ee3ff5-f6f2-4628-93b8-e38eb31806b0.mp3",
      value: "CwhRBWXzGAHq8TQ4Fs17",
      name: "Roger",
      label: "Roger",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "EXAVITQu4vr4xnSDxMaL",
      gender: "female",
      age: "young",
      style: "soft",
      accent: "American",
      description: "news",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/01a3e33c-6e99-4ee7-8543-ff2216a32186.mp3",
      value: "EXAVITQu4vr4xnSDxMaL",
      name: "Sarah",
      label: "Sarah",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "FGY2WhTYpPnrIDTdsKH5",
      gender: "female",
      age: "young",
      style: "upbeat",
      accent: "American",
      description: "social media",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/FGY2WhTYpPnrIDTdsKH5/67341759-ad08-41a5-be6e-de12fe448618.mp3",
      value: "FGY2WhTYpPnrIDTdsKH5",
      name: "Laura",
      label: "Laura",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "IKne3meq5aSn9XLyUdCD",
      gender: "male",
      age: "middle aged",
      style: "natural",
      accent: "Australian",
      description: "conversational",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
      value: "IKne3meq5aSn9XLyUdCD",
      name: "Charlie",
      label: "Charlie",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "JBFqnCBsd6RMkjVDRZzb",
      gender: "male",
      age: "middle aged",
      style: "warm",
      accent: "British",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/e6206d1a-0721-4787-aafb-06a6e705cac5.mp3",
      value: "JBFqnCBsd6RMkjVDRZzb",
      name: "George",
      label: "George",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "N2lVS1w4EtoT3dr4eOWO",
      gender: "male",
      age: "middle-aged",
      style: "intense",
      accent: "Transatlantic",
      description: "characters",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
      value: "N2lVS1w4EtoT3dr4eOWO",
      name: "Callum",
      label: "Callum",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "SAz9YHcvj6GT2YYXdXww",
      gender: "non-binary",
      age: "middle-aged",
      style: "confident",
      accent: "American",
      description: "social media",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/SAz9YHcvj6GT2YYXdXww/e6c95f0b-2227-491a-b3d7-2249240decb7.mp3",
      value: "SAz9YHcvj6GT2YYXdXww",
      name: "River",
      label: "River",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "TX3LPaxmHKxFdv7VOQHJ",
      gender: "male",
      age: "young",
      style: "articulate",
      accent: "American",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
      value: "TX3LPaxmHKxFdv7VOQHJ",
      name: "Liam",
      label: "Liam",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "XB0fDUnXU5powFXDhCwa",
      gender: "female",
      age: "young",
      style: "seductive",
      accent: "Swedish",
      description: "characters",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
      value: "XB0fDUnXU5powFXDhCwa",
      name: "Charlotte",
      label: "Charlotte",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "Xb7hH8MSUJpSbSDYk0k2",
      gender: "female",
      age: "middle-aged",
      style: "confident",
      accent: "British",
      description: "news",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/d10f7534-11f6-41fe-a012-2de1e482d336.mp3",
      value: "Xb7hH8MSUJpSbSDYk0k2",
      name: "Alice",
      label: "Alice",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "XrExE9yKIg1WjnnlVkGX",
      gender: "female",
      age: "middle-aged",
      style: "friendly",
      accent: "American",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
      value: "XrExE9yKIg1WjnnlVkGX",
      name: "Matilda",
      label: "Matilda",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "bIHbv24MWmeRgasZH58o",
      gender: "male",
      age: "young",
      style: "friendly",
      accent: "American",
      description: "social media",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/bIHbv24MWmeRgasZH58o/8caf8f3d-ad29-4980-af41-53f20c72d7a4.mp3",
      value: "bIHbv24MWmeRgasZH58o",
      name: "Will",
      label: "Will",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "cgSgspJ2msm6clMCkdW9",
      gender: "female",
      age: "young",
      style: "expressive",
      accent: "American",
      description: "conversational",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/cgSgspJ2msm6clMCkdW9/56a97bf8-b69b-448f-846c-c3a11683d45a.mp3",
      value: "cgSgspJ2msm6clMCkdW9",
      name: "Jessica",
      label: "Jessica",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "cjVigY5qzO86Huf0OWal",
      gender: "male",
      age: "middle-aged",
      style: "friendly",
      accent: "American",
      description: "conversational",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/cjVigY5qzO86Huf0OWal/d098fda0-6456-4030-b3d8-63aa048c9070.mp3",
      value: "cjVigY5qzO86Huf0OWal",
      name: "Eric",
      label: "Eric",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "iP95p4xoKVk53GoZ742B",
      gender: "male",
      age: "middle-aged",
      style: "casual",
      accent: "American",
      description: "conversational",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/3f4bde72-cc48-40dd-829f-57fbf906f4d7.mp3",
      value: "iP95p4xoKVk53GoZ742B",
      name: "Chris",
      label: "Chris",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "nPczCjzI2devNBz1zQrb",
      gender: "male",
      age: "middle-aged",
      style: "deep",
      accent: "American",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/2dd3e72c-4fd3-42f1-93ea-abc5d4e5aa1d.mp3",
      value: "nPczCjzI2devNBz1zQrb",
      name: "Brian",
      label: "Brian",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "onwK4e9ZLuTAKqWW03F9",
      gender: "male",
      age: "middle-aged",
      style: "authoritative",
      accent: "British",
      description: "news",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
      value: "onwK4e9ZLuTAKqWW03F9",
      name: "Daniel",
      label: "Daniel",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "pFZP5JQG7iQjIQuC4Bku",
      gender: "female",
      age: "middle-aged",
      style: "warm",
      accent: "British",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/89b68b35-b3dd-4348-a84a-a3c13a3c2b30.mp3",
      value: "pFZP5JQG7iQjIQuC4Bku",
      name: "Lily",
      label: "Lily",
      provider: "ELEVENLABS"
    },
    {
      voiceId: "pqHfZKP75CvOlQylNhV4",
      gender: "male",
      age: "old",
      style: "trustworthy",
      accent: "American",
      description: "narration",
      previewURL: "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/d782b3ff-84ba-4029-848c-acf01285524d.mp3",
      value: "pqHfZKP75CvOlQylNhV4",
      name: "Bill",
      label: "Bill",
      provider: "ELEVENLABS"
    }
]
;
